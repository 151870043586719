import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: _to => {
      return {
        path: '/login2'
      }
    },
  }, 
  {
    path: '/login2',  
    component: () => import(/* webpackChunkName: "login1" */ '../views/Login_2.vue'),
    redirect: _to => {
      return {
        name: 'Logoin'
      }
    },
    children: [
      {
        path: '/login1',
        name: 'Logoin',
        component: () => import(/* webpackChunkName: "login1" */ '../views/Login_1.vue')
      },
      {
        path: '/ResetPwd',
        component: () => import(/* webpackChunkName: "ResetPwd" */ '../views/ResetPwd.vue')
      },
      {
        path: '/forgetPwd',
        component: () => import(/* webpackChunkName: "ResetPwd" */ '../views/forgetPwd.vue')
      }, 
    ]
  },
 
  {
    path: '/Authority', 
    component: () => import(/* webpackChunkName: "ResetPwd" */ '../views/Authority.vue')
  },
  {
    path: '/Platform', 
    component: () => import(/* webpackChunkName: "ResetPwd" */ '../views/Platform.vue')
  },
  {
    path: '/Platform_db', 
    component: () => import(/* webpackChunkName: "ResetPwd" */ '../views/Platform_db.vue')
  },
  
  {
    path: '*',
    component:() => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
